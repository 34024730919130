var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.isShowLoading}},[_c('b-card',{attrs:{"title":_vm.$t('master.company.form')}},[_c('validation-observer',{ref:"validateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveData($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.company.code') + _vm.showAsterisk}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.company.code'),"rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"company-code",attrs:{"state":errors.length > 0 ? false : null,"plaintext":!!_vm.id},model:{value:(_vm.companyCode),callback:function ($$v) {_vm.companyCode=$$v},expression:"companyCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.company.name') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.company.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.company.tel') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.company.tel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.companyTel),callback:function ($$v) {_vm.companyTel=$$v},expression:"companyTel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.company.contactName') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.company.contactName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.companyContactName),callback:function ($$v) {_vm.companyContactName=$$v},expression:"companyContactName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.company.contactTel') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.company.contactTel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.companyContactTel),callback:function ($$v) {_vm.companyContactTel=$$v},expression:"companyContactTel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6","align-self":"end"}},[_c('b-form-group',{staticClass:"pb-1"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":"Y"},model:{value:(_vm.companyInternal),callback:function ($$v) {_vm.companyInternal=$$v},expression:"companyInternal"}},[_vm._v(" "+_vm._s(_vm.$t('master.company.internal'))+" ")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"success","disabled":_vm.isBtnDisabled}},[(_vm.isBtnDisabled)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t('general.btnSubmit'))+" ")],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }