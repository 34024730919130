<template>
  <div>
    <b-overlay :show="isShowLoading">
      <b-card :title="$t('master.company.form')">

        <validation-observer ref="validateForm" #default="{invalid}">
          <b-form @submit.prevent="saveData">
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('master.company.code') + showAsterisk">
                  <validation-provider #default="{ errors }" :name="$t('master.company.code')" rules="required|max:20">
                    <b-form-input v-model="companyCode" :state="errors.length > 0 ? false : null" :plaintext="!!id" class="company-code"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('master.company.name') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('master.company.name')" rules="required">
                    <b-form-input v-model="companyName" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('master.company.tel') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('master.company.tel')" rules="required">
                    <b-form-input v-model="companyTel" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('master.company.contactName') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('master.company.contactName')" rules="required">
                    <b-form-input v-model="companyContactName" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('master.company.contactTel') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('master.company.contactTel')" rules="required">
                    <b-form-input v-model="companyContactTel" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" align-self="end">
                <b-form-group class="pb-1">
                  <b-form-checkbox
                      v-model="companyInternal"
                      value="Y"
                      class="custom-control-primary"
                  >
                    {{ $t('master.company.internal') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-center">
                <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                  <b-spinner small v-if="isBtnDisabled"/>
                  <feather-icon icon="SaveIcon" v-else/>
                  {{ $t('general.btnSubmit') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BButton, BOverlay, BFormCheckbox} from 'bootstrap-vue'
import {required, min, max, regex} from '@validations'
import formMixins from "@/mixins/formMixins"
import {CompanyService} from "@/services"

const companyService = new CompanyService()

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    BOverlay,
    BFormCheckbox,
  },
  mixins: [formMixins],
  async created() {
    this.id = this.$route.params.id

    if(this.id) {
      await this.getData()
    }

    this.isShowLoading = false
    this.enabledCheckBackButton()
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    companyCode: '',
    companyName: '',
    companyTel: '',
    companyContactName: '',
    companyContactTel: '',
    companyInternal: '',
  }),
  computed: {
    showAsterisk() {
      return this.id ? '' : '*'
    },
  },
  methods: {
    async getData() {
      let queryResult = await companyService.getData(this.id)
      if(queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.companyCode = result.companyCode
        this.companyName = result.companyName
        this.companyTel = result.companyTel
        this.companyContactName = result.companyContactName
        this.companyContactTel = result.companyContactTel
        this.companyInternal = result.companyInternal
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'pageMasterCompany'})
      }
    },
    saveData() {
      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.isBtnDisabled = true
          let actionData = {
            companyCode: this.companyCode,
            companyName: this.companyName,
            companyTel: this.companyTel,
            companyContactName: this.companyContactName,
            companyContactTel: this.companyContactTel,
            companyInternal: this.companyInternal ? this.companyInternal : 'N',
          }

          let saveResult

          if (this.id) {
            saveResult = await companyService.updateData(this.id, actionData)
          } else {
            saveResult = await companyService.createData(actionData)
          }

          this.isBtnDisabled = false

          if (saveResult.isSuccess) {
            this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
            await this.$router.push({name: 'pageMasterCompany'})
          } else {
            this.$store.commit('main/setToastError', saveResult.data)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.company-code {
  text-transform: uppercase;
}
</style>
